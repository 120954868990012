import React from "react";

import Aws from "../images/home-page/partners/aws.png";
import SalesForce from "../images/home-page/partners/salesforce.png";
import Wso2 from "../images/home-page/partners/wso2.png";
import Ibm from "../images/home-page/partners/ibm.png";
import Istqb from "../images/home-page/partners/istqb.png";
import FinTech from "../images/home-page/partners/fintech.png";
import MS from "../images/home-page/partners/mspartner.png";
import AwsPrtnr from "../images/partners/aws-partner.png";
import SalesForcePrtnr from "../images/partners/salesforce-partner.png";
import IstqbPrtnr from "../images/partners/istqb-platinum.png";

const OurPartners = () => {
  return (
    <div id="section-our-partners">
      {" "}
      <div className="title-1 mb-2">Our partners</div>
      <div className="section-discription">
        We partner with some of the best in the business. These relationships
        enable us to design and deliver fit-for-purpose solutions that remove
        friction, streamline processes, and reveal deep insights.
      </div>
      <div className="section-our-partners-logo-set">
        <a href="https://partners.amazonaws.com/partners/001E00000100a6xIAA/iTelaSoft" target="_blank">
          <div className="wrap-logo">
            <div className="partners-logo">
              {" "}
              <img src={Aws} alt=""></img> 
              {/* <img src={AwsPrtnr} alt=""></img>  */}
            </div>
            <div className="logo-dec">Select Tier Services Partner</div>
          </div>
        </a>
        <a href="https://appexchange.salesforce.com/appxConsultingListingDetail?listingId=a0N4V00000IdvYGUAZ" target="_blank">
          <div className="wrap-logo">
            <div className="partners-logo">
              {" "}
              <img src={SalesForce} alt=""></img>
              {/* <img src={SalesForcePrtnr} alt=""></img>  */}
            </div>
            <div className="logo-dec">Consulting Partner</div>
          </div>
        </a>
        <a href="https://wso2.com/partners/?c=Australia&p=&t=" target="_blank">
          <div className="wrap-logo">
            <div className="partners-logo">
              {" "}
              <img src={Wso2} alt=""></img>
            </div>
            <div className="logo-dec">Value Added Reseller Partner</div>
          </div>
        </a>
        <a href="https://www.istqb.org/" target="_blank">
          <div className="wrap-logo">
            <div className="partners-logo">
              {" "}
              <img src={Istqb} alt=""></img>
              {/* <img src={IstqbPrtnr} alt=""></img> */}
            </div>
            <div className="logo-dec">Platinum Partner</div>
          </div>
        </a>
        <a href="" target="_blank">
          <div className="wrap-logo">
            <div className="partners-logo">
              {" "}
              <img src={Ibm} alt=""></img>
            </div>
            <div className="logo-dec">Silver Partner</div>
          </div>
        </a>
        <a href="https://www.fintechaustralia.org.au/corporate-partners" target="_blank">
          <div className="wrap-logo">
            <div className="partners-logo">
              {" "}
              <img src={FinTech} alt=""></img>
            </div>
            <div className="logo-dec">Ecosystem Partner</div>
          </div>
        </a>
        <a href="https://www.microsoft.com/en-lk/" target="_blank">
          <div className="wrap-logo">
            <div className="partners-logo">
              {" "}
              <img src={MS} alt=""></img>
            </div>
            <div className="logo-dec">Microsoft Partner</div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default OurPartners;
